<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card>
      <div class="date-picker-container">
        <b-row class="align-items-center text-center">
          <!-- Geri Butonu -->
          <b-col cols="2">
            <b-button variant="primary" class="btn-icon" block @click="dayBack">
              <feather-icon icon="ArrowLeftIcon" size="16" />
            </b-button>
          </b-col>

          <!-- Tarih Seçici -->
          <b-col cols="8" class="mb-1 mt-1">
            <b-form-datepicker
              v-model="selectDate"
              placeholder="Tarih"
              :date-format="'YYYY-MM-DD'"
              @input="changeDate()"
              class="w-100 text-center"
            ></b-form-datepicker>
          </b-col>

          <!-- İleri Butonu -->
          <b-col cols="2">
            <b-button variant="primary" class="btn-icon" block @click="dayNext">
              <feather-icon icon="ArrowRightIcon" size="16" />
            </b-button>
          </b-col>
        </b-row>
      </div>
      <DxDataGrid
        :dataSource="records"
        :show-borders="true"
        :remote-operations="true"
        key-expr="id"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
        height="75vh"
      >
        <DxExport :enabled="true" :allow-export-selected-data="true" />
        <DxGroupPanel :visible="true" />
        <DxGrouping :auto-expand-all="false" />

        <DxPaging :enabled="true" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
        />
        <DxHeaderFilter :visible="true" />
        <DxFilterRow :visible="true" />
        <DxFilterPanel :visible="true" />
        <DxColumn dataField="branch.branchName" caption="Şube Adı" />
        <DxColumn dataField="kayitNo" caption="Kayıt No" sort-order="desc" />
        <DxColumn dataField="kayitRefNo" caption="Kayıt Referans No" />
        <DxColumn dataField="durum" caption="Durum" />

        <DxColumn
          dataField="randevuTarihi"
          caption="Randevu Tarihi"
          dataType="datetime"
        />
        <DxColumn dataField="randevuGunAdi" caption="Randevu Gün Adı" />
        <DxColumn
          dataField="randevuKayitTarihi"
          caption="Randevu Kayıt Tarihi"
          dataType="datetime"
        />
        <DxColumn
          dataField="randevuKayitGunAdi"
          caption="Randevu Kayıt Gün Adı"
        />
        <DxColumn
          dataField="randevuSonKayitTarihi"
          caption="Randevu Son Kayıt Tarihi"
          dataType="datetime"
        />
        <DxColumn
          dataField="iptalEdenKullanici"
          caption="İptal Eden Kullanıcı"
        />
        <DxColumn dataField="randevuMecrasi" caption="Randevu Mecrası" />
        <DxColumn dataField="randevuAciklama" caption="Randevu Açıklama" />
        <DxColumn
          dataField="randevuKaydedenKullanici"
          caption="Randevu Kaydeden Kullanıcı"
        />
        <DxColumn
          dataField="randevuKaydedenKullaniciGrubu"
          caption="Randevu Kaydeden Kullanıcı Grubu"
        />
        <DxColumn dataField="hizmetKayitTarihi" caption="Hizmet Kayıt Tarihi" />
        <DxColumn
          dataField="hizmetVerildiTarihi"
          caption="Hizmet Verildi Tarihi"
        />

        <DxColumn
          dataField="sonKayitTarihiAciklama"
          caption="Son Kayıt Tarihi Açıklama"
        />
        <DxColumn dataField="subeAdi" caption="Şube Adı" />
        <DxColumn dataField="servisAdi" caption="Servis Adı" />
        <DxColumn dataField="hizmetAdi" caption="Hizmet Adı" />
        <DxColumn dataField="servisUcreti" caption="Servis Ücreti" />
        <DxColumn dataField="doktorAdi" caption="Doktor Adı" />
        <DxColumn dataField="hastaNo" caption="Hasta No" />
        <DxColumn dataField="hastaAdi" caption="Hasta Adı" />
        <DxColumn dataField="hastaAdSoyad" caption="Hasta Ad Soyad" />
        <DxColumn dataField="telefon" caption="Telefon" />
        <DxColumn dataField="telefonGsm" caption="Telefon GSM" />
        <DxColumn dataField="hastaUyruk" caption="Hasta Uyruk" />
        <DxColumn dataField="hastaIlAdi" caption="Hasta İl Adı" />
        <DxColumn dataField="hastaIlceAdi" caption="Hasta İlçe Adı" />
        <DxColumn dataField="hastaUlke" caption="Hasta Ülke" />
        <DxColumn dataField="hastaCinsiyet" caption="Hasta Cinsiyet" />
        <DxColumn
          dataField="dogumTarihi"
          caption="Doğum Tarihi"
          dataType="date"
        />
        <DxColumn
          dataField="satirIptalZamani"
          caption="Satır İptal Zamanı"
          dataType="date"
        />
        <DxColumn
          dataField="updateTime"
          caption="Güncelleme Zamanı"
          dataType="date"
        />
        <DxColumn dataField="isActive" caption="Aktif Mi?" dataType="boolean" />
        <DxColumn
          dataField="createdAt"
          caption="Oluşturulma Tarihi"
          dataType="date"
        />
      </DxDataGrid>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxLookup,
  DxHeaderFilter,
  DxFilterRow,
  DxFilterPanel,
  DxPager,
  DxPaging,
  DxGroupPanel,
  DxGrouping,
  DxExport,
} from "devextreme-vue/data-grid";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxLookup,
    DxHeaderFilter,
    DxFilterRow,
    DxFilterPanel,
    DxPager,
    DxPaging,
    DxGroupPanel,
    DxGrouping,
    DxExport,
    ToastificationContent,
  },
  data() {
    return {
      pageSizes: [50, 100, 200, 500],
      show: false,
      records: [],
      selectDate: this.getYesterday(),
    };
  },
  async created() {
    this.show = true;

    await this.getTasksByDateRange();
    this.show = false;
    // Sayfa yüklendiğinde Ocak ayı ile başlayalım
  },
  methods: {
    // Dünkü tarihi al
    getYesterday() {
      const date = new Date();
      date.setDate(date.getDate() - 1);

      // Yerel saat dilimine göre formatlanmış tarih
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Aylar 0'dan başlar
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`; // YYYY-MM-DD formatında döner
    },
    async changeDate() {
      const currentDate = new Date(this.selectDate);

      this.selectDate = currentDate.toISOString().split("T")[0];
      this.show = true;
      await this.getTasksByDateRange();

      this.show = false;
    },
    // İleri tarih
    async dayNext() {
      const currentDate = new Date(this.selectDate);
      currentDate.setDate(currentDate.getDate() + 1);
      this.selectDate = currentDate.toISOString().split("T")[0];
      this.show = true;
      await this.getTasksByDateRange();

      this.show = false;
    },
    // Geri tarih
    async dayBack() {
      const currentDate = new Date(this.selectDate);
      currentDate.setDate(currentDate.getDate() - 1);
      this.selectDate = currentDate.toISOString().split("T")[0];

      this.show = true;
      await this.getTasksByDateRange();

      this.show = false;
    },
    async getTasksByDateRange() {
      const sendDate = this.selectDate;

      try {
        const response = await this.$http.get(
          `randevu/GetByDateRange?date=${sendDate}`
        );

        this.records = response.data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Hata",
            icon: "AlertCircleIcon",
            variant: "danger",
            text: error.response?.data?.message || "Bir hata oluştu.",
          },
        });
      } finally {
        this.show = false;
      }
    },
    formatDate(date) {
      if (!date) return null;
      const d = new Date(date);
      return d.toISOString();
    },
  },
};
</script>
