<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-row>
      <b-col lg="4" md="6">
        <b-card no-body class="text-center p-3">
          <div>
            <h4 class="mb-3 font-weight-bold">Dün Verilen Randevular</h4>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h1 class="text-success">
                {{ overallTotals.totalPrice | currency }}
              </h1>
              <span>Toplam Tutar</span>
            </div>
            <div>
              <h1 class="text-primary">
                {{ overallTotals.totalCount | numberFormat }}
              </h1>
              <span>Toplam Randevu</span>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col lg="4" md="6">
        <b-card no-body>
          <b-row>
            <b-col
              v-for="(citys, index) in totalCityDatas"
              :key="index"
              cols="12"
            >
              <b-card no-body class="p-0 m-0">
                <b-card-body
                  class="d-flex align-items-center justify-content-between p-0 m-1"
                >
                  <div class="d-flex align-items-center">
                    <b-avatar size="34" :variant="getVariant(citys.totalCount)">
                      <feather-icon size="16" icon="CalendarIcon" />
                    </b-avatar>

                    <div class="ml-1">
                      <h5 class="mb-0">
                        {{ citys.totalCount }}
                      </h5>
                      <span class="text-muted"> {{ citys.city }} </span>
                    </div>
                  </div>
                  <h5 class="text-primary">
                    {{ citys.totalPrice | currency }}
                  </h5>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col lg="4" md="6">
        <b-card no-body>
          <b-row>
            <b-col
              v-for="(service, index) in formattedServices"
              :key="index"
              cols="12"
            >
              <b-card no-body class="p-0 m-0">
                <b-card-body
                  class="d-flex align-items-center justify-content-between p-0 m-1"
                >
                  <div class="d-flex align-items-center">
                    <div
                      :class="[
                        'avatar rounded-circle',
                        getServiceAvatar(service.serviceName).color,
                      ]"
                      style="
                        width: 40px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <feather-icon
                        :icon="getServiceAvatar(service.serviceName).icon"
                        size="18"
                      />
                    </div>

                    <div class="ml-1">
                      <h5 class="mb-0">
                        {{ service.totalCount }}
                      </h5>
                      <span class="text-muted">
                        {{ service.serviceName }}
                      </span>
                    </div>
                  </div>
                  <h5 class="text-primary">
                    {{ service.averagePrice | currency }}
                  </h5>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardBody,
  BAvatar,
  BRow,
  BCol,
  BContainer,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
    BRow,
    BCol,
    BContainer,
  },
  props: {
    selectedDate: {
      type: String,
      required: true,
    },
  },
  watch: {
    selectedDate(newDate) {
      // Prop değiştiğinde API çağrısı yap
      this.getRandevuBrans(newDate);
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      services: [],
      cities: [],
      totalBlock: [],
    };
  },
  computed: {
    formattedServices() {
      return this.services.map((service) => {
        let totalCount = 0;
        let totalPrice = 0;

        Object.values(service.branchCounts).forEach((branch) => {
          totalCount += branch.count;
          totalPrice += branch.totalPrice;
        });

        return {
          serviceName: service.serviceName,
          totalCount,
          averagePrice: totalCount > 0 ? totalPrice : 0,
        };
      });
    },
    totalCityDatas() {
      const totals = {};

      this.cities.forEach((service) => {
        Object.entries(service.branchCounts).forEach(([city, values]) => {
          if (!totals[city]) {
            totals[city] = { totalCount: 0, totalPrice: 0 };
          }
          totals[city].totalCount += values.count;
          totals[city].totalPrice += values.totalPrice;
        });
      });

      return Object.entries(totals).map(
        ([city, { totalCount, totalPrice }]) => ({
          city,
          totalCount,
          totalPrice,
        })
      );
    },
    overallTotals() {
      let totalCount = 0;
      let totalPrice = 0;

      this.totalBlock.forEach((service) => {
        Object.values(service.branchCounts).forEach((values) => {
          totalCount += values.count;
          totalPrice += values.totalPrice;
        });
      });

      return { totalCount, totalPrice };
    },
  },
  methods: {
    async getRandevuBrans(date) {
      this.show = true;
      try {
        const response = await this.$http.get(
          `randevu/randevu-hastane-brans-price?date=${date}`
        );
        this.services = response.data; // API'den gelen veriyi doğrudan atıyoruz
        this.cities = response.data;
        this.totalBlock = response.data;

        this.show = false;
      } catch (error) {
        this.$toast.error("Veri alınırken bir hata oluştu!");
      }
    },
    getAvatarVariant(index) {
      const variants = ["primary", "success", "danger", "warning", "info"];
      return variants[index % variants.length];
    },
    getServiceAvatar(serviceName) {
      const avatarConfigs = {
        "Fiziksel Tıp ve Rehabilitasyon": {
          icon: "MonitorIcon",
          color: "bg-light-primary",
        },
        Radyoloji: { icon: "ActivityIcon", color: "bg-light-success" },
        Nöroloji: { icon: "UserIcon", color: "bg-light-warning" },
        "Spor Hekimliği": { icon: "ActivityIcon", color: "bg-light-danger" },
        Romatoloji: { icon: "HeartIcon", color: "bg-light-info" },
        "Beslenme ve Diyetisyen": {
          icon: "PieChartIcon",
          color: "bg-light-warning",
        },
        "İç Hastalıkları": { icon: "TrendingUpIcon", color: "bg-light-info" },
      };
      return (
        avatarConfigs[serviceName] || {
          icon: "CircleIcon",
          color: "bg-light-muted",
        }
      );
    },
    getVariant(count) {
      if (count < 10) {
        return "light-danger"; // Kırmızı
      } else if (count >= 10 && count < 30) {
        return "light-warning"; // Sarı
      } else {
        return "light-success"; // Yeşil
      }
    },
  },
  filters: {
    currency(value) {
      return new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
      }).format(value);
    },
  },
  created() {
    this.getRandevuBrans(this.selectedDate);
  },
};
</script>

<style scoped>
h5 {
  font-weight: bold;
}
</style>
