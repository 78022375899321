<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-row>
      <b-col cols="12" md="12" lg="3">
        <b-card>
          <b-row>
            <b-col class="text-left">
              <h1 class="text-success">
                {{ randevuSummary.today.totalAmount | currency }}
              </h1>
              <span>Toplam Tutar</span>
            </b-col>
            <b-col class="text-right">
              <h1 class="text-primary">
                {{ randevuSummary.today.totalRandevuCount | numberFormat }}
              </h1>
              <span>Toplam Randevu</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" md="12" lg="6">
        <b-card>
          <b-row class="align-items-center text-center">
            <!-- Geri Butonu -->
            <b-col cols="2">
              <b-button
                variant="primary"
                class="btn-icon"
                block
                @click="dayBack"
              >
                <feather-icon icon="ArrowLeftIcon" size="16" />
              </b-button>
            </b-col>

            <!-- Tarih Seçici -->
            <b-col cols="8" class="mb-1 mt-1">
              <b-form-datepicker
                v-model="selectDate"
                placeholder="Tarih"
                :date-format="'YYYY-MM-DD'"
                @input="changeDate()"
                class="w-100 text-center"
              ></b-form-datepicker>
            </b-col>

            <!-- İleri Butonu -->
            <b-col cols="2">
              <b-button
                variant="primary"
                class="btn-icon"
                block
                @click="dayNext"
              >
                <feather-icon icon="ArrowRightIcon" size="16" />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" md="12" lg="3">
        <b-card>
          <b-row>
            <b-col class="text-left">
              <h1 class="text-success">
                {{ randevuSummary.future.totalAmount | currency }}
              </h1>
              <span>Toplam Tutar</span>
            </b-col>
            <b-col class="text-right">
              <h1 class="text-primary">
                {{ randevuSummary.future.totalRandevuCount | numberFormat }}
              </h1>
              <span>Toplam Randevu</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" lg="9">
        <b-row>
          <b-col md="6" lg="4">
            <b-card class="p-0">
              <div class="text-center justify-content-center">
                <b-card-text>
                  Toplam
                  <span class="font-large-2 font-weight-bold">
                    {{ randevuTarihliResponse.totalCount }}
                  </span>
                </b-card-text>
              </div>

              <hr class="mt-2 mb-2" />
              <div class="d-flex justify-content-between">
                <div class="text-center">
                  <b-card-text class="mb-2"> Gerçekleşen </b-card-text>
                  <span class="font-large-1 font-weight-bold">
                    {{ randevuTarihliResponse.okCount }}
                  </span>
                </div>
                <div class="text-center">
                  <b-card-text class="mb-2"> Gelmedi </b-card-text>
                  <span class="font-large-1 font-weight-bold">
                    {{ randevuTarihliResponse.gelmediCount }}
                  </span>
                </div>
                <div class="text-center">
                  <b-card-text class="mb-2"> İptal Edilen </b-card-text>
                  <span class="font-large-1 font-weight-bold">
                    {{ randevuTarihliResponse.iptalCount }}
                  </span>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col md="6" lg="8">
            <b-card class="p-0">
              <b-card-header class="mb-1 p-0">
                <span>Gerçekleşen Randevu Sayıları</span>
                <b-card-text>
                  <span>{{ subeRandevuSayilari.date | formatDate }} </span>
                  <feather-icon
                    icon="RefreshCcwIcon"
                    size="15"
                    class="text-muted cursor-pointer"
                    @click="getSubelerRandevuTarihi"
                  />
                </b-card-text>
              </b-card-header>

              <b-row>
                <b-col
                  v-for="branch in subeRandevuSayilari.branchItems"
                  :key="branch.branchId"
                  cols="6"
                  sm="6"
                  md="4"
                  lg="3"
                  class="mb-1"
                >
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-avatar
                        size="38"
                        :variant="getVariantOveral(branch.overal)"
                      >
                        <feather-icon size="13" icon="PercentIcon" />
                        {{ branch.overal }}
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder">
                        {{ branch.okCount }} / {{ branch.totalCount }}
                        <feather-icon
                          size="18"
                          :icon="getArrowIconOveral(branch.overal)"
                          :class="getTextVariantOveral(branch.overal)"
                        />
                      </h4>
                      <b-card-text class="font-small-4 m-0">
                        {{ branch.branchName }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" lg="4">
            <b-card>
              <b-card-title class="text-center mt-1">
                Dün Kaydedilen
              </b-card-title>
              <hr class="mt-2 mb-2" />
              <div class="d-flex justify-content-between">
                <div class="text-center">
                  <b-card-text class="mb-2"> Yeni Randevu </b-card-text>
                  <span class="font-large-1 font-weight-bold">
                    {{ kayitTarihliResponse.totalCount }}
                  </span>
                </div>
                <div class="text-center">
                  <b-card-text class="mb-2"> Değiştirilenler </b-card-text>
                  <span class="font-large-1 font-weight-bold">
                    {{ kayitTarihliResponse.guncellemeCount }}
                  </span>
                </div>
                <div class="text-center">
                  <b-card-text class="mb-2"> İptaller </b-card-text>
                  <span class="font-large-1 font-weight-bold">
                    {{ kayitTarihliResponse.iptalCount }}
                  </span>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col md="6" lg="8">
            <b-card class="p-0">
              <b-card-header class="mb-1 p-0">
                <span>Dün Kaydedilen Randevular</span>

                <b-card-text>
                  <span>{{ subeRandevuKayitSayilari.date | formatDate }} </span>
                  <feather-icon
                    icon="RefreshCcwIcon"
                    size="15"
                    class="text-muted cursor-pointer"
                    @click="getSubelerRandevuTarihi"
                  />
                </b-card-text>
              </b-card-header>

              <b-row>
                <b-col
                  v-for="branch in subeRandevuKayitSayilari.branchItems"
                  :key="branch.branchId"
                  cols="6"
                  sm="6"
                  md="4"
                  lg="3"
                  class="mb-1"
                >
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-avatar
                        size="38"
                        :variant="getVariant(branch.totalCount)"
                      >
                        <feather-icon size="22" icon="CalendarIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder">
                        {{ branch.totalCount }}
                        <feather-icon
                          size="18"
                          :icon="getArrowIcon(branch.totalCount)"
                          :class="getTextVariant(branch.totalCount)"
                        />
                      </h4>
                      <b-card-text class="font-small-4 mb-0">
                        {{ branch.branchName }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12" lg="3">
        <randevularCh />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <randevu15gun
          :selectedDate="selectDate"
          @dateChanged="handleDateChange"
        />
      </b-col>
    </b-row>
    <b-row class="match-height mt-2">
      <b-col lg="6" class="mt-1">
        <randevu4Hafta
          :selectedDate="selectDate"
          @dateChanged="handleDateChange"
        />
      </b-col>
      <b-col lg="6" class="mt-1">
        <randevuGun
          :selectedDate="selectDate"
          @dateChanged="handleDateChange"
        />
      </b-col>
    </b-row>
    <b-row class="match-height mt-2">
      <b-col lg="12">
        <randevuHastaneBrans
          :selectedDate="selectDate"
          @dateChanged="handleDateChange"
        />
      </b-col>
    </b-row>
    <b-row class="match-height mt-1">
      <b-col cols="12" md="12" lg="12">
        <randevuHBP
          :selectedDate="selectDate"
          @dateChanged="handleDateChange"
        />
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import randevuAylikHaftalik from "../Component/randevularAylikHaftalar.vue";
import randevularCh from "../Component/randevularChart.vue";
import randevu15gun from "../Component/randevular15gun.vue";
import randevu4Hafta from "../Component/randevuSon4Hafta.vue";
import randevuHastaneBrans from "../Component/randevuHastaneBrans.vue";
import randevuHBP from "../Component/randevuHastaneBransFiyat.vue";
import randevuGun from "../Component/randevuGunBazliTekrar.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    randevuAylikHaftalik,
    randevularCh,
    randevu15gun,
    randevuHastaneBrans,
    randevuHBP,
    randevu4Hafta,
    randevuGun,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectDate: this.getYesterday(),
      show: false,
      startDate: "", // Başlangıç tarihi
      endDate: "", // Bitiş tarihi
      appointments: [], // API'den gelen veriler
      pagerOptions: {
        visible: true,
        showPageSizeSelector: true,
        allowedPageSizes: [50, 100, 200],
        showInfo: true,
        showNavigationButtons: true,
      },
      randevuTarihliResponse: {
        totalCount: 0,
        okCount: 0,
        iptalCount: 0,
        gelmediCount: 0,
        date: "",
      },
      kayitTarihliResponse: {
        totalCount: 0,
        okCount: 0,
        iptalCount: 0,
        guncellemeCount: 0,
        gelmediCount: 0,
        date: "",
      },
      subeRandevuSayilari: {
        branchItems: [],
        date: "",
      },
      subeRandevuKayitSayilari: {
        branchItems: [],
        date: "",
      },
      randevuSummary: {
        today: {
          totalRandevuCount: 0,
          totalAmount: 0,
        },
        future: {
          totalRandevuCount: 0,
          totalAmount: 0,
        },
      },
    };
  },
  async created() {
    this.show = true;
    await this.getRandevuTarihi();
    await this.getKayitTarihi();
    await this.getSubelerRandevuTarihi();
    await this.getSubelerRandevuKayitTarihi();
    await this.GetTodaysRandevuSummary();
    this.show = false;
    // Sayfa yüklendiğinde Ocak ayı ile başlayalım
  },
  filters: {
    currency(value) {
      return new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
      }).format(value);
    },
  },
  methods: {
    handleDateChange(newDate) {
      console.log(`Randevu bileşeninden gelen tarih: ${newDate}`);
    },
    async changeDate() {
      const currentDate = new Date(this.selectDate);

      this.selectDate = currentDate.toISOString().split("T")[0];
      this.show = true;
      await this.getRandevuTarihi();
      await this.getKayitTarihi();
      await this.getSubelerRandevuTarihi();
      await this.getSubelerRandevuKayitTarihi();
      this.show = false;
    },
    // BUgün ve ileriki tarihlerin toplam randevu sayısı ve fiyat bilgisi
    async GetTodaysRandevuSummary() {
      this.$http
        .get("randevu/GetTodaysRandevuSummary")
        .then((response) => {
          const data = response.data;
          this.randevuSummary = {
            today: {
              totalRandevuCount: data.today.totalRandevuCount,
              totalAmount: data.today.totalAmount,
            },
            future: {
              totalRandevuCount: data.future.totalRandevuCount,
              totalAmount: data.future.totalAmount,
            },
          };
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    //
    async fetchData() {
      this.show = true;
      this.$http
        .get("randevu", {
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
          },
        })
        .then((response) => {
          this.show = false;
          this.appointments = response.data;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    // Dünkü tarihi al
    getYesterday() {
      const date = new Date();
      date.setDate(date.getDate() - 1);

      // Yerel saat dilimine göre formatlanmış tarih
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Aylar 0'dan başlar
      const day = String(date.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`; // YYYY-MM-DD formatında döner
    },
    // İleri tarih
    async dayNext() {
      const currentDate = new Date(this.selectDate);
      currentDate.setDate(currentDate.getDate() + 1);
      this.selectDate = currentDate.toISOString().split("T")[0];
      this.show = true;
      await this.getRandevuTarihi();
      await this.getKayitTarihi();
      await this.getSubelerRandevuTarihi();
      await this.getSubelerRandevuKayitTarihi();
      this.show = false;
    },
    // Geri tarih
    async dayBack() {
      const currentDate = new Date(this.selectDate);
      currentDate.setDate(currentDate.getDate() - 1);
      this.selectDate = currentDate.toISOString().split("T")[0];

      this.show = true;
      await this.getRandevuTarihi();
      await this.getKayitTarihi();
      await this.getSubelerRandevuTarihi();
      await this.getSubelerRandevuKayitTarihi();
      this.show = false;
    },
    // Randevu bilgilerini API'den al
    async getRandevuTarihi() {
      const sendDate = this.selectDate;

      try {
        const response = await this.$http.get(
          `randevu/randevu-sayisi-gerceklesme?date=${sendDate}`
        );

        this.randevuTarihliResponse = response.data;
      } catch (error) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Hata`,
            icon: "CoffeeIcon",
            variant: "danger",
            text: error.response?.data?.message || "Bir hata oluştu",
          },
        });
      }
    },
    //randevu-sayisi-kayit
    async getKayitTarihi() {
      const sendDate = this.selectDate;

      this.$http
        .get(`randevu/randevu-sayisi-kayit?date=${sendDate}`)
        .then((response) => {
          this.kayitTarihliResponse = response.data;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    //randevu-sube-kayitlari Gerçekleme tarihi
    async getSubelerRandevuTarihi() {
      const sendDate = this.selectDate;
      this.$http
        .get(`randevu/randevu-sube-kayitlari?date=${sendDate}`)
        .then((response) => {
          this.subeRandevuSayilari = response.data;
          //  console.log(response.data);
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    //randevu-sube-kayitlari-kayit-tarihi
    async getSubelerRandevuKayitTarihi() {
      const sendDate = this.selectDate;
      this.$http
        .get(`randevu/randevu-sube-kayitlari-kayit-tarihi?date=${sendDate}`)
        .then((response) => {
          this.subeRandevuKayitSayilari = response.data;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    getVariantOveral(count) {
      if (count < 80) {
        return "light-danger"; // Kırmızı
      } else if (count >= 80 && count < 95) {
        return "light-warning"; // Sarı
      } else {
        return "light-success"; // Yeşil
      }
    },
    getArrowIconOveral(count) {
      if (count < 80) {
        return "TrendingDownIcon"; // Aşağı ok
      } else if (count >= 80 && count < 95) {
        return "ActivityIcon"; // Aktivite simgesi
      } else {
        return "TrendingUpIcon"; // Yukarı ok
      }
    },
    getTextVariantOveral(count) {
      if (count < 80) {
        return "text-danger";
      } else if (count >= 80 && count < 95) {
        return "text-warning";
      } else {
        return "text-success";
      }
    },
    getVariant(count) {
      if (count < 10) {
        return "light-danger"; // Kırmızı
      } else if (count >= 10 && count < 30) {
        return "light-warning"; // Sarı
      } else {
        return "light-success"; // Yeşil
      }
    },
    getArrowIcon(count) {
      if (count < 10) {
        return "TrendingDownIcon"; // Aşağı ok
      } else if (count >= 10 && count < 30) {
        return "ActivityIcon"; // Aktivite simgesi
      } else {
        return "TrendingUpIcon"; // Yukarı ok
      }
    },
    getTextVariant(count) {
      if (count < 10) {
        return "text-danger";
      } else if (count >= 10 && count < 30) {
        return "text-warning";
      } else {
        return "text-success";
      }
    },
  },
};
</script>
<style>
@media (max-width: 768px) {
  .btn-icon {
    padding: 0.5rem;
  }
  .form-control-sm {
    font-size: 0.875rem;
  }
}
</style>
