<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card class="m-0 p-0">
      <b-card-header class="m-0 p-0">
        <b-card-title>
          <h5 class="mb-1">
            Gün Bazlı İstatistikler <b>({{ day }})</b>
          </h5>
        </b-card-title>
      </b-card-header>
      <b-card-body class="m-0 p-0">
        <vue-apex-charts
          :key="chartKey"
          type="bar"
          height="400"
          :options="lineChartOptions.chartOptions"
          :series="lineChartOptions.series"
        />
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { BCard, BCardHeader, BCardBody, BCardTitle } from "bootstrap-vue";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
  }, //69B8FF
  props: {
    selectedDate: {
      type: String,
      required: true,
    },
  },
  watch: {
    selectedDate(newDate) {
      // Prop değiştiğinde API çağrısı yap
      this.getLast(newDate);
    },
  },
  created() {
    this.getLast(this.selectedDate);
  },
  data() {
    return {
      show: false,
      chartKey: 0,
      day: "",
      lineChartOptions: {
        series: [], // Seriler API'den gelecek
        chartOptions: {
          chart: {
            type: "bar", // Bar grafiği
            toolbar: { show: true },
          },
          plotOptions: {
            borderRadius: 10,
            bar: {
              borderRadius: 10,
              columnWidth: "45%",
              dataLabels: {
                position: "center", // top, center, bottom
              },
            },
          },
          colors: ["#69B8FF"], // Çubukların rengi
          dataLabels: {
            enabled: true,
            style: {
              colors: ["#000", "#000"],
            },
          },
          xaxis: {
            categories: [], // X ekseni verileri API'den doldurulacak
          },
          yaxis: {
            title: { text: "" }, // Y ekseni başlığı
          },
          grid: {
            borderColor: "#f1f3fa",
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
        },
      },
    };
  },
  methods: {
    async getLast(date) {
      this.show = true; // Yüklenme animasyonu
      try {
        const response = await this.$http.get(
          `randevu/GetDayData?date=${date}`
        );
        const data = response.data;
        const tarih = new Date(date);
        const gunler = [
          "Pazar",
          "Pazartesi",
          "Salı",
          "Çarşamba",
          "Perşembe",
          "Cuma",
          "Cumartesi",
        ];
        this.day = gunler[tarih.getDay()];

        // Veriyi tarihe göre sırala
        const sortedData = data.sort((a, b) => {
          return new Date(a.tarih) - new Date(b.tarih);
        });

        // X ekseni için tarihler ve Y ekseni için randevu sayılarını ayıkla
        const categories = sortedData.map((item) => {
          const tarih = new Date(item.tarih);
          return `${tarih.getDate()}-${
            tarih.getMonth() + 1
          }-${tarih.getFullYear()}`;
        });

        const seriesData = sortedData.map((item) => item.randevuSayisi);

        // Grafik verilerini güncelle
        this.lineChartOptions.series = [
          {
            name: "Randevu Sayısı",
            data: seriesData,
          },
        ];

        this.lineChartOptions.chartOptions.xaxis.categories = categories;

        // Grafik yeniden oluşturulsun diye key artır
        this.chartKey += 1;

        this.show = false; // Yüklenme animasyonunu kapat
      } catch (error) {
        this.show = false; // Hata durumunda yüklenmeyi gizle
        console.error("API Hatası:", error);
      }
    },
  },
};
</script>
