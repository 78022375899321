<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card class="m-0 p-0">
      <b-card-header class="m-0 p-0">
        <b-card-title>
          <h5 class="mb-1">Haftalık Randevu İstatistikleri</h5>
        </b-card-title>
      </b-card-header>
      <b-card-body class="m-0 p-0">
        <vue-apex-charts
          :key="chartKey"
          type="bar"
          height="400"
          :options="lineChartOptions.chartOptions"
          :series="lineChartOptions.series"
        />
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { BCard, BCardHeader, BCardBody, BCardTitle } from "bootstrap-vue";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
  }, //69B8FF
  props: {
    selectedDate: {
      type: String,
      required: true,
    },
  },
  watch: {
    selectedDate(newDate) {
      // Prop değiştiğinde API çağrısı yap
      this.getLast(newDate);
    },
  },
  created() {
    this.getLast(this.selectedDate);
  },
  data() {
    return {
      show: false,
      chartKey: 0,
      lineChartOptions: {
        series: [], // Seriler API'den gelecek
        chartOptions: {
          chart: {
            type: "bar", // Bar grafiği
            toolbar: { show: true },
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              columnWidth: "45%",
              dataLabels: {
                position: "center", // top, center, bottom
                style: {
                  colors: "#fff",
                },
              },
            },
          },
          colors: ["#69B8FF"], // Çubukların rengi
          dataLabels: {
            enabled: true,
            style: {
              colors: ["#000", "#000"],
            },
          },
          xaxis: {
            categories: [], // X ekseni verileri API'den doldurulacak
          },
          yaxis: {
            title: { text: "" }, // Y ekseni başlığı
          },
          grid: {
            borderColor: "#f1f3fa",
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
        },
      },
    };
  },
  methods: {
    async getLast(date) {
      this.show = true; // Yüklenme animasyonu
      try {
        const response = await this.$http.get(
          `randevu/GetWeeklyData?date=${date}`
        );
        const data = response.data;

        // Veriyi grafiğe uygun hale getir
        this.lineChartOptions.chartOptions.xaxis.categories = data.labels; // Tarihler
        this.lineChartOptions.series = [
          {
            name: "Randevu Sayısı",
            data: data.data, // Randevu sayıları
          },
        ];

        // Grafik güncelleme
        this.chartKey += 1;
        this.show = false;
      } catch (error) {
        this.show = false; // Hata durumunda yüklenmeyi gizle
        console.error("API Hatası:", error);
      }
    },
  },
};
</script>
